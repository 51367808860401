import { FC } from 'react'
import { AdditionalServicesPaymentSchedule } from 'components/AdditionalServices'
import DesktopNavigation from 'components/DesktopNavigation'
import { UserProtectedRoute } from 'routes'
import { useIntl } from 'react-intl'

const AdditionalServicesPaymentSchedulePage: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <UserProtectedRoute>
      <DesktopNavigation
        label={formatMessage({
          id: 'additionalServicesMedia.schedule',
          defaultMessage: 'Harmonogram płatności'
        })}
        desktopNavigateTo='/additional-services'
      />
      <AdditionalServicesPaymentSchedule />
    </UserProtectedRoute>
  )
}

export default AdditionalServicesPaymentSchedulePage
