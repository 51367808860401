import { FC, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Button, Modal } from '@mui/material'
import { useBenefitsList } from 'services/benefits/benefits.hooks'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import { useIntl } from 'react-intl'

import { styled } from '@mui/material/styles'

const Div = styled('div')(({ theme }) => ({
  p: {
    ...theme.typography.body1
  },
  h1: {
    ...theme.typography.h1
  },
  h2: {
    ...theme.typography.h2
  },
  h3: {
    ...theme.typography.h3
  },
  h4: {
    ...theme.typography.h4
  },
  h5: {
    ...theme.typography.h5
  },
  h6: {
    ...theme.typography.h6
  }
}))

const Discount: FC = () => {
  const { id } = useParams()
  const { benefitsList = [] } = useBenefitsList()
  const { formatMessage } = useIntl()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const benefit = useMemo(
    () => benefitsList.find((benefit) => benefit.id === Number(id)),
    [id, benefitsList]
  )

  const handleOpen = () => setIsModalOpen(true)
  const handleClose = () => setIsModalOpen(false)

  if (benefit) {
    return (
      <>
        <BackNavigationHeader label={benefit.name} />
        <BackNavigationContent sx={{ position: 'relative' }}>
          {!!benefit.logoThumbnail && (
            <>
              <Box
                sx={{
                  width: '100%',
                  height: '200px',
                  objectFit: 'contain',
                  cursor: 'pointer'
                }}
                component='img'
                src={benefit.logoThumbnail}
                alt={benefit.name}
                onClick={handleOpen}
              />

              <Modal
                open={isModalOpen}
                onClose={handleClose}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <Box
                  sx={{
                    width: '80%',
                    maxHeight: '80%',
                    objectFit: 'contain',
                    outline: 'none'
                  }}
                  component='img'
                  src={benefit.logoThumbnail}
                  alt={benefit.name}
                />
              </Modal>
            </>
          )}
          {!!benefit.pdf && (
            <Button
              sx={{
                my: 3
              }}
              component='a'
              href={benefit.pdf}
              target='_blank'
              variant='contained'
              fullWidth>
              {formatMessage({
                id: 'additionalServicesAttachments.attachments',
                defaultMessage: 'Załączniki'
              })}
            </Button>
          )}
          <Div dangerouslySetInnerHTML={{ __html: benefit.descriptionLocal }} />
        </BackNavigationContent>
      </>
    )
  }

  return null
}

export default Discount
