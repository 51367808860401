import { useMutation } from '@tanstack/react-query'
import { apiInstance } from 'services/api'
import { useMasquaradeToken } from 'services/masquarade/masquarade.hooks'
import { useAccessToken } from 'services/token/token.hooks'

export const useHelpdeskTcketsCreate = () => {
  const { accessToken } = useAccessToken()
  const { masquaradeToken } = useMasquaradeToken()

  const { mutateAsync, ...rest } = useMutation({
    mutationFn: ({
      topic,
      description,
      faultCategory,
      flatId
    }: {
      topic: string
      description: string
      faultCategory: number
      flatId?: number
      buildingId?: number
      faultDate?: string
    }) =>
      apiInstance({
        url: '/helpdesk/tickets/',
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
          'mask-authorization': !!masquaradeToken ? `Token ${masquaradeToken}` : undefined
        },
        data: {
          topic,
          description,
          fault_category: faultCategory,
          flatId: flatId,
          buildingId: null,
          faultDate: null
        }
      })
  })

  return { createTicket: mutateAsync, ...rest }
}
