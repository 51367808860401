import { FC } from 'react'
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import {
  KeyboardArrowRight,
  HomeOutlined,
  DescriptionOutlined,
  AccountBalanceWalletOutlined,
  // PersonAddAltOutlined,
  // NoteAddOutlined,
  InsertChartOutlined,
  AttachFileOutlined
} from '@mui/icons-material'
import { useNavigate, useParams } from 'react-router-dom'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import { useLeaseContract } from 'services/contracts/hooks/useLeaseContracts'
import { AdditionalServicesAverageUtilityConsumption } from 'components/AdditionalServices'
import { AcceptanceProtocolListItem } from 'components/AcceptanceProtocol'
import { useIntl } from 'react-intl'
import { useLeaseContractsMediaMetersList } from 'services/contracts/hooks/useLeaseContractsMediaMetersList'

const AdditionalServicesMedia: FC = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const { id } = useParams()
  const { leaseContract } = useLeaseContract(Number(id))
  const { leaseContractsMediaMetersList } = useLeaseContractsMediaMetersList(Number(id))

  return (
    <>
      <BackNavigationHeader
        label={formatMessage({
          id: 'additionalServicesMedia.schedule',
          defaultMessage: 'Harmonogram płatności'
        })}
      />
      <BackNavigationContent>
        <AdditionalServicesAverageUtilityConsumption />
        <List>
          <ListItem
            onClick={() => window.open(leaseContract?.leaseContractFile, '_blank')}
            disablePadding
            sx={{
              borderBottom: '1px solid #CDCDCD',
              borderTop: '1px solid #CDCDCD'
            }}
            secondaryAction={<KeyboardArrowRight color='disabled' />}>
            <ListItemButton sx={{ py: 2 }}>
              <ListItemIcon>
                <DescriptionOutlined color='primary' />
              </ListItemIcon>
              <ListItemText
                primary={formatMessage({
                  id: 'additionalServicesMedia.preview',
                  defaultMessage: 'Podgląd umowy'
                })}
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            onClick={() => navigate(`/additional-services/${id}/annexes`)}
            disablePadding
            sx={{
              borderBottom: '1px solid #CDCDCD'
            }}
            secondaryAction={<KeyboardArrowRight color='disabled' />}>
            <ListItemButton sx={{ py: 2 }}>
              <ListItemIcon>
                <AttachFileOutlined color='primary' />
              </ListItemIcon>
              <ListItemText
                primary={formatMessage({
                  id: 'additionalServicesMedia.annexes',
                  defaultMessage: 'Aneksy'
                })}
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            onClick={() => navigate(`/additional-services/${id}/payment-schedule`)}
            disablePadding
            sx={{
              borderBottom: '1px solid #CDCDCD'
            }}
            secondaryAction={<KeyboardArrowRight color='disabled' />}>
            <ListItemButton sx={{ py: 2 }}>
              <ListItemIcon>
                <AccountBalanceWalletOutlined color='primary' />
              </ListItemIcon>
              <ListItemText
                primary={formatMessage({
                  id: 'additionalServicesMedia.schedule',
                  defaultMessage: 'Harmonogram płatności'
                })}
              />
            </ListItemButton>
          </ListItem>

          {/* <ListItem
            onClick={() => navigate(`/additional-services/${id}/attachments`)}
            disablePadding
            sx={{
              borderBottom: '1px solid #CDCDCD',
            }}
            secondaryAction={<KeyboardArrowRight color='disabled' />}>
            <ListItemButton sx={{ py: 2 }}>
              <ListItemIcon>
                <AttachFileOutlined color='primary' />
              </ListItemIcon>
              <ListItemText primary='Załączniki' />
            </ListItemButton>
          </ListItem> */}

          {/* <ListItem
            onClick={() => navigate(`/additional-services/${id}/tenants`)}
            disablePadding
            sx={{
              borderBottom: "1px solid #CDCDCD",
            }}
            secondaryAction={<KeyboardArrowRight color="disabled" />}
          >
            <ListItemButton sx={{ py: 2 }}>
              <ListItemIcon>
                <PersonAddAltOutlined color="primary" />
              </ListItemIcon>
              <ListItemText primary="Lokatorzy do podpisu" />
            </ListItemButton>
          </ListItem> */}

          {/* <ListItem
            onClick={() => navigate(`/additional-services/${id}/documents`)}
            disablePadding
            sx={{
              borderBottom: "1px solid #CDCDCD",
            }}
            secondaryAction={<KeyboardArrowRight color="disabled" />}
          >
            <ListItemButton sx={{ py: 2 }}>
              <ListItemIcon>
                <NoteAddOutlined color="primary" />
              </ListItemIcon>
              <ListItemText primary="Dokumenty do podpisu" />
            </ListItemButton>
          </ListItem> */}

          <ListItem
            onClick={() => navigate(`/additional-services/${id}/information`)}
            disablePadding
            sx={{
              borderBottom: '1px solid #CDCDCD'
            }}
            secondaryAction={<KeyboardArrowRight color='disabled' />}>
            <ListItemButton sx={{ py: 2 }}>
              <ListItemIcon>
                <HomeOutlined color='primary' />
              </ListItemIcon>
              <ListItemText
                primary={formatMessage({
                  id: 'additionalServicesMedia.information',
                  defaultMessage: 'Podstawowe informacje'
                })}
              />
            </ListItemButton>
          </ListItem>
          {!!leaseContractsMediaMetersList.length && (
            <ListItem
              onClick={() => navigate(`/additional-services/${id}/media`)}
              disablePadding
              sx={{
                borderBottom: '1px solid #CDCDCD'
              }}
              secondaryAction={<KeyboardArrowRight color='disabled' />}>
              <ListItemButton sx={{ py: 2 }}>
                <ListItemIcon>
                  <InsertChartOutlined color='primary' />
                </ListItemIcon>
                <ListItemText
                  primary={formatMessage({
                    id: 'additionalServicesMedia.media',
                    defaultMessage: 'Media'
                  })}
                />
              </ListItemButton>
            </ListItem>
          )}
          <AcceptanceProtocolListItem />
        </List>
      </BackNavigationContent>
    </>
  )
}

export default AdditionalServicesMedia
