import AdditionalServicesBookingAgreement from 'components/AdditionalServices/AdditionalServicesBookingAgreement'
import DesktopNavigation from 'components/DesktopNavigation'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { UserProtectedRoute } from 'routes'

const AdditionalServicesBookingAgreementPage: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <UserProtectedRoute>
      <DesktopNavigation
        label={formatMessage({
          id: 'additionalServicesBookingAgreement.title',
          defaultMessage: 'Umowa rezerwacyjna'
        })}
        desktopNavigateTo='/additional-services'
      />
      <AdditionalServicesBookingAgreement />
    </UserProtectedRoute>
  )
}

export default AdditionalServicesBookingAgreementPage
