import { LoadingButton } from '@mui/lab'
import { Box, CircularProgress, TextField, Tooltip, Typography } from '@mui/material'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import DesktopNavigation from 'components/DesktopNavigation'
import MobileBottomFooter from 'components/MobileBottomFooter'
import Section from 'components/Section'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import MuiMarkdown from 'mui-markdown'
import { FC, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useOfferListOffer } from 'services/offer/hooks/useOfferList'
import { useOfferMessagesCreate } from 'services/offer/hooks/useOfferMessagesCreate'
import { useOfferMessagesList } from 'services/offer/hooks/useOfferMessagesList'
import { formatDate } from 'utils'

const OfferMessages: FC = () => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const { id } = useParams()
  const { formatMessage } = useIntl()
  const { createMessage } = useOfferMessagesCreate()
  const { data: offerDetail = [], isFetching, isLoading } = useOfferListOffer(id)
  const { offerMessagesList, refetchMessagesList } = useOfferMessagesList(offerDetail.offerId)

  const isSameDay = (date1: string, date2: string) => {
    return dayjs(date1).format('YYYY-MM-DD') === dayjs(date2).format('YYYY-MM-DD')
  }

  const formik = useFormik<{
    message: string
  }>({
    initialValues: {
      message: ''
    },
    onSubmit: async ({ message }, { setSubmitting }) => {
      setSubmitting(true)

      await createMessage({ message, offerId: offerDetail.offerId })
      await refetchMessagesList()

      formik.setFieldValue('message', '')
      setSubmitting(false)
    }
  })

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
  }, [offerMessagesList])

  if (isFetching || isLoading) {
    return (
      <Section
        sx={{
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <CircularProgress />
      </Section>
    )
  }

  return (
    <>
      <DesktopNavigation
        label={formatMessage({
          id: 'offerMessage.header',
          defaultMessage: 'Zadaj pytanie'
        })}
      />
      <BackNavigationHeader
        label={formatMessage({
          id: 'offerMessage.header',
          defaultMessage: 'Zadaj pytanie'
        })}
      />
      <BackNavigationContent>
        <Box
          ref={scrollRef}
          sx={{
            maxHeight: '75vh',
            overflowY: 'auto',
            paddingTop: 'env(safe-area-inset-top)',
            paddingBottom: 'calc(env(safe-area-inset-top) + 6rem)'
          }}>
          <Box>
            <Box sx={{ maxHeight: { lg: '500px' } }}>
              {offerMessagesList.map(({ createdAt, message, person, user }, index) => {
                const previousMessage = offerMessagesList[index - 1]
                const isNewDay =
                  !previousMessage || !isSameDay(previousMessage.createdAt, createdAt)

                return (
                  <>
                    {isNewDay && (
                      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
                        <Box sx={{ borderTop: '1px solid #ccc', flexGrow: 1, mr: 1 }} />
                        <Typography variant='body2' sx={{ whiteSpace: 'nowrap' }}>
                          {dayjs(createdAt).format('DD.MM.YYYY')}
                        </Typography>
                        <Box sx={{ borderTop: '1px solid #ccc', flexGrow: 1, ml: 1 }} />
                      </Box>
                    )}
                    <Box
                      mt={2}
                      sx={{
                        display: 'flex',
                        justifyContent: person ? 'flex-start' : 'flex-end',
                        alignItems: user ? 'flex-start' : 'flex-end',
                        flexDirection: 'column'
                      }}>
                      <Tooltip title={dayjs(createdAt).format('DD/MM/YYYY HH:mm:ss')}>
                        <Box
                          sx={{
                            border: person ? '1px solid rgba(0,0,0,.12)' : '#F2F6FF',
                            backgroundColor: person ? '#fff' : '#F2F6FF',
                            p: 2,
                            borderRadius: 1,
                            maxWidth: '70%',
                            width: 'auto'
                          }}>
                          <MuiMarkdown>{message}</MuiMarkdown>
                        </Box>
                      </Tooltip>
                      <Box sx={{ display: 'flex', gap: '5px' }}>
                        <Typography variant='body2' display='block' sx={{ fontSize: '12px' }}>
                          {formatDate(createdAt)}
                        </Typography>
                        <Typography variant='body2' display='block' sx={{ fontSize: '12px' }}>
                          {user
                            ? formatMessage({
                                id: 'offerMessage.admin',
                                defaultMessage: 'Administrator'
                              })
                            : formatMessage({
                                id: 'offerMessage.me',
                                defaultMessage: 'Ja'
                              })}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                )
              })}
            </Box>
          </Box>
        </Box>
        <MobileBottomFooter>
          <TextField
            name='message'
            onChange={formik.handleChange}
            value={formik.values.message}
            label={formatMessage({
              id: 'defectMessage.form.message',
              defaultMessage: 'Wiadomość'
            })}
            fullWidth
            sx={{ mb: 2 }}
            rows={5}
            multiline
            disabled={formik.isSubmitting}
          />
          <LoadingButton
            fullWidth
            sx={{ marginTop: { lg: '20px' }, marginBottom: { lg: '20px' } }}
            variant='contained'
            loading={formik.isSubmitting}
            onClick={formik.submitForm}>
            {formatMessage({
              id: 'offerMessage.send',
              defaultMessage: 'Wyślij'
            })}
          </LoadingButton>
        </MobileBottomFooter>
      </BackNavigationContent>
    </>
  )
}

export default OfferMessages
