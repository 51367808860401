import { FC } from 'react'
import {
  Typography,
  TextField,
  Box,
  Input,
  Badge,
  Tooltip,
  IconButton,
  useMediaQuery
} from '@mui/material'
import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import DesktopNavigation from 'components/DesktopNavigation'
import { useHelpdeskTicketsList } from 'services/helpdesk/hooks'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import MobileBottomFooter from 'components/MobileBottomFooter'
import { useFormik } from 'formik'
import {
  useHelpdeskTicketsMessagesCreate,
  useHelpdeskTicketsMessagesList
} from 'services/helpdesk/hooks'
import { useIntl } from 'react-intl'
import { useDropzone } from 'react-dropzone'
import theme from 'styles/theme'

const DefectMessage: FC = () => {
  const { id } = useParams()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const { getTicket } = useHelpdeskTicketsList()
  const { createMessage } = useHelpdeskTicketsMessagesCreate()
  const { refetchMessagesList } = useHelpdeskTicketsMessagesList(Number(id))
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const ticket = getTicket(Number(id))

  const formik = useFormik<{
    message: string
    attachments: File[]
  }>({
    initialValues: {
      message: '',
      attachments: []
    },
    onSubmit: async ({ message, attachments }, { setSubmitting }) => {
      setSubmitting(true)

      await createMessage({
        message,
        attachment: attachments.length === 1 ? attachments[0] : null,
        ticketId: Number(id)
      })

      if (attachments.length >= 2) {
        for (const attachment of attachments) {
          await createMessage({
            message: '',
            attachment,
            ticketId: Number(id)
          })
        }
      }

      await refetchMessagesList()
      navigate(`/faults/report/${id}`)
    }
  })

  const Dropzone = ({ onDrop }: { onDrop: (files: File[]) => void }) => {
    const { getRootProps, getInputProps } = useDropzone({
      accept: 'image/*' as any,
      noClick: true,
      onDrop,
      multiple: true,
      maxFiles: 5
    })

    return (
      <div
        {...getRootProps()}
        style={{ border: '2px dashed #ccc', padding: '20px', textAlign: 'center' }}>
        <input {...getInputProps()} />
        <Typography variant='h6' fontWeight={400} mb={1}>
          {formatMessage({
            id: 'defectsReportFault.dragAndDrop',
            defaultMessage: 'Przeciągnij i upuść swoje pliki tutaj'
          })}
        </Typography>
      </div>
    )
  }

  const handleDrop = (acceptedFiles: File[]) => {
    const currentAttachments = formik.values.attachments
    const filesToAdd = acceptedFiles.slice(0, 5 - currentAttachments.length) // Limit the files to 5
    formik.setFieldValue('attachments', [...currentAttachments, ...filesToAdd]) // Add new files
  }

  if (ticket) {
    return (
      <>
        <DesktopNavigation
          label={formatMessage({
            id: 'defectMessage.addMessage',
            defaultMessage: 'Dodaj wiadomość'
          })}
        />
        <BackNavigationHeader
          label={formatMessage({
            id: 'defectMessage.addMessage',
            defaultMessage: 'Dodaj wiadomość'
          })}
        />
        <BackNavigationContent>
          <Box>
            <TextField
              name='message'
              onChange={formik.handleChange}
              value={formik.values.message}
              label={formatMessage({
                id: 'defectMessage.form.message',
                defaultMessage: 'Wiadomość'
              })}
              sx={{ mt: 4 }}
              fullWidth
              rows={5}
              multiline
              disabled={formik.isSubmitting}
            />
          </Box>
          <Box mx={-1}>
            {formik.values.attachments.map((image, key) => (
              <Badge
                color='primary'
                key={`${image.name}_${key}`}
                sx={{
                  '.MuiBadge-badge': {
                    width: '32px',
                    height: '32px',
                    borderRadius: '50%'
                  },
                  mt: 3,
                  mx: 1
                }}
                badgeContent={
                  <IconButton
                    size='small'
                    onClick={() => {
                      formik.setFieldValue(
                        'attachments',
                        formik.values.attachments.filter((_, k) => k !== key)
                      )
                    }}>
                    <Close fontSize='small' color='secondary' />
                  </IconButton>
                }>
                <Tooltip title={image.name} placement='top'>
                  <Box
                    sx={{
                      width: '96px',
                      height: '96px',
                      backgroundImage: `url("${URL.createObjectURL(image)}")`,
                      borderRadius: 1,
                      border: '1px solid',
                      borderColor: 'grey.400',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover'
                    }}
                  />
                </Tooltip>
              </Badge>
            ))}
            <Input
              fullWidth
              type='file'
              sx={{ mt: 2 }}
              placeholder={formatMessage({
                id: 'defectMessage.form.file',
                defaultMessage: 'Dodaj zdjęcie'
              })}
              inputProps={{
                accept: 'image/*'
              }}
              name='attachment'
              onChange={(e: any) => {
                if (!!e.target.files.length) {
                  formik.setFieldValue('attachments', [
                    ...formik.values.attachments,
                    e.target.files[0]
                  ])

                  return (e.target.value = '')
                }

                return (e.target.value = '')
              }}
            />
            {isDesktop && (
              <Box>
                <Typography sx={{ textAlign: 'center', marginTop: 4 }}>
                  {formatMessage({
                    id: 'defectsReportFault.or',
                    defaultMessage: 'Lub'
                  })}
                </Typography>
                <Box
                  style={{
                    padding: '20px',
                    textAlign: 'center',
                    marginTop: '20px'
                  }}>
                  <Dropzone onDrop={handleDrop} />
                </Box>
              </Box>
            )}
            <Typography fontSize={12} color='grey' mt={1}>
              {formatMessage({
                id: 'common.file.multimedia',
                defaultMessage: 'Pliki w formacie .jpg, .png, .jpeg, .webp, .pdf'
              })}
            </Typography>
          </Box>
          <MobileBottomFooter>
            <Typography variant='body2' textAlign='center'>
              {formatMessage({
                id: 'defectMessage.adminReplay',
                defaultMessage:
                  'Wiadomość została przekazana do osoby odpowiedzialnej, niebawem wrócimy z odpowiedzią'
              })}
            </Typography>
            <Box mt={3}>
              {!formik?.values?.message && formik?.values?.attachments.length === 0 && (
                <Typography variant='body2' textAlign='center'>
                  {formatMessage({
                    id: 'defectMessage.disabledButtonInfo',
                    defaultMessage: 'Uzupełnij wiadomość lub wybierz plik'
                  })}
                </Typography>
              )}
              <LoadingButton
                fullWidth
                sx={{ mt: 2 }}
                variant='contained'
                loading={formik.isSubmitting}
                onClick={formik.submitForm}
                disabled={!formik?.values?.message && formik?.values?.attachments?.length === 0}>
                {formatMessage({
                  id: 'defectMessage.form.submit',
                  defaultMessage: 'WYśLIJ'
                })}
              </LoadingButton>
            </Box>
          </MobileBottomFooter>
        </BackNavigationContent>
      </>
    )
  }

  return null
}

export default DefectMessage
