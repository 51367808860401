import { FC } from 'react'
import { AdditionalServicesMedia } from 'components/AdditionalServices'
import RestrictedForDesktop from 'components/RestrictedForDesktop'
import DesktopNavigation from 'components/DesktopNavigation'
import { UserProtectedRoute } from 'routes'
import { useIntl } from 'react-intl'

const AdditionalServicesPage: FC = () => {
  const { formatMessage } = useIntl()

  return (
    <UserProtectedRoute>
      <DesktopNavigation
        label={formatMessage({
          id: 'additionalServicesMedia.additionalServices',
          defaultMessage: 'Usługi dodatkowe'
        })}
        desktopNavigateTo='/additional-services'
      />
      <RestrictedForDesktop>
        <AdditionalServicesMedia />
      </RestrictedForDesktop>
    </UserProtectedRoute>
  )
}

export default AdditionalServicesPage
