import { Box, Button, Typography } from '@mui/material'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import DesktopNavigation from 'components/DesktopNavigation'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

const OfferAccept = () => {
  const navigation = useNavigate()
  const { formatMessage } = useIntl()

  return (
    <>
      <DesktopNavigation
        label={formatMessage({
          id: 'offer.backToPanel',
          defaultMessage: 'Wróć do panelu'
        })}
      />
      <BackNavigationHeader
        label={formatMessage({
          id: 'offer.backToPanel',
          defaultMessage: 'Wróć do panelu'
        })}
      />
      <BackNavigationContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          height: '100%'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px'
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              gap: '10px',
              rowGap: '20px'
            }}>
            <Typography sx={{ fontWeight: 400, fontSize: '18px' }}>
              {formatMessage({
                id: 'offer.hello',
                defaultMessage: 'Dzień dobry,'
              })}
            </Typography>
            <Typography
              sx={{ fontWeight: 400, fontSize: '18px', lineHeight: '35px', textAlign: 'justify' }}>
              {formatMessage({
                id: 'offer.acceptMessage',
                defaultMessage:
                  'Dziękujemy za zaakceptowanie oferty. Twój opiekun skontaktuje się z Tobą w celu dokończenia procesu rezerwacji lokalu i podpisania umowy rezerwacyjnej. Akceptacja oferty nie jest równoznaczna z rezerwacją.'
              })}
            </Typography>
            <Box>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '18px',
                  lineHeight: '35px',
                  textAlign: 'justify'
                }}>
                {formatMessage({
                  id: 'offer.letsStayInTouch',
                  defaultMessage: 'Pozostańmy w kontakcie!'
                })}
              </Typography>
              <Typography sx={{ fontWeight: 400, fontSize: '18px', textAlign: 'justify' }}>
                {formatMessage({
                  id: 'offer.R4Rteam',
                  defaultMessage: 'Zespół Resi4Rent'
                })}
              </Typography>
            </Box>
          </Box>

          <Button
            variant='contained'
            sx={{ width: '315px', marginTop: '20px' }}
            onClick={() => navigation('/')}>
            {formatMessage({
              id: 'offer.backToPanel',
              defaultMessage: 'Wróć do panelu'
            })}
          </Button>
        </Box>
      </BackNavigationContent>
    </>
  )
}

export default OfferAccept
