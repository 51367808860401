import { FC } from 'react'
import { Box, Button, Typography } from '@mui/material'
import {
  useLeaseContractFlats,
  useLeaseContractFlatsAndSpaces
} from 'services/contracts/hooks/useLeaseContractsFlats'
import { useActiveLeaseContractId } from 'services/contracts/hooks/useActiveLeaseContractId'
import BackNavigationHeader, { BackNavigationContent } from 'components/BackNavigationHeader'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'

const AdditionalServicesInformation: FC = () => {
  const { id } = useParams()
  const { formatMessage } = useIntl()
  const { activeLeaseContractId } = useActiveLeaseContractId()
  const { leaseContractsFlats } = useLeaseContractFlats(Number(id))
  const { leaseContractsFlatAccessCodes } = useLeaseContractFlatsAndSpaces(activeLeaseContractId)

  return (
    <>
      <BackNavigationHeader
        label={formatMessage({
          id: 'additionalServicesInformation.information',
          defaultMessage: 'Podstawowe informacje'
        })}
      />
      <BackNavigationContent>
        <Box>
          {leaseContractsFlatAccessCodes.map(
            ({
              id,
              flatIdNumber,
              gateCode,
              investmentContactNumber,
              investmentWifiLogin,
              investmentWifiPassword,
              wicketCode,
              wifiLogin,
              wifiPassword
            }) => (
              <Box key={`${flatIdNumber}_${id}`} mb={2}>
                <Typography textTransform='capitalize' variant='h4' fontWeight={500} mb={2}>
                  {flatIdNumber}
                </Typography>
                <Box display='flex' justifyContent='space-between' mb={1}>
                  <Typography>
                    {formatMessage({
                      id: 'additionalServicesInformation.gateCode',
                      defaultMessage: 'Kod do klatki schodowej'
                    })}
                  </Typography>
                  <Typography fontWeight={700}>{gateCode || 'n/a'}</Typography>
                </Box>
                <Box display='flex' justifyContent='space-between' mb={1}>
                  <Typography>
                    {formatMessage({
                      id: 'additionalServicesInformation.wicketCode',
                      defaultMessage: 'Kod do furtki'
                    })}
                  </Typography>
                  <Typography fontWeight={700}>{wicketCode || 'n/a'}</Typography>
                </Box>
                <Box display='flex' justifyContent='space-between' mb={1}>
                  <Typography>
                    {formatMessage({
                      id: 'additionalServicesInformation.wifiLogin',
                      defaultMessage: 'Login do Wifi'
                    })}
                  </Typography>
                  <Typography fontWeight={700}>{wifiLogin || 'n/a'}</Typography>
                </Box>
                <Box display='flex' justifyContent='space-between' mb={1}>
                  <Typography>
                    {formatMessage({
                      id: 'additionalServicesInformation.wifiPassword',
                      defaultMessage: 'Hasło do Wifi'
                    })}
                  </Typography>
                  <Typography fontWeight={700}>{wifiPassword || 'n/a'}</Typography>
                </Box>
                <Box display='flex' justifyContent='space-between' mb={1}>
                  <Typography>
                    {formatMessage({
                      id: 'additionalServicesInformation.investmentWifiLogin',
                      defaultMessage: 'Login do Wifi (inwestycja)'
                    })}
                  </Typography>
                  <Typography fontWeight={700}>{investmentWifiLogin || 'n/a'}</Typography>
                </Box>
                <Box display='flex' justifyContent='space-between' mb={1}>
                  <Typography>
                    {formatMessage({
                      id: 'additionalServicesInformation.investmentWifiPassword',
                      defaultMessage: 'Hasło do Wifi (inwestycja)'
                    })}
                  </Typography>
                  <Typography fontWeight={700}>{investmentWifiPassword || 'n/a'}</Typography>
                </Box>
                <Box display='flex' justifyContent='space-between' mb={1}>
                  <Typography>
                    {formatMessage({
                      id: 'additionalServicesInformation.investmentContactNumber',
                      defaultMessage: 'Numer kontaktowy'
                    })}
                  </Typography>
                  <Typography fontWeight={700}>{investmentContactNumber || 'n/a'}</Typography>
                </Box>
                {leaseContractsFlats.length > 0 && (
                  <Box
                    display='flex'
                    justifyContent='space-between'
                    mb={1}
                    sx={{ cursor: 'pointer' }}>
                    <Button
                      href={leaseContractsFlats[0]?.energyCertificate}
                      target='_blank'
                      variant='contained'
                      sx={{ marginTop: '20px' }}>
                      {formatMessage({
                        id: 'additionalServicesInformation.energyCertificate',
                        defaultMessage: 'Certyfikat energetyczny'
                      })}
                    </Button>
                  </Box>
                )}
              </Box>
            )
          )}
        </Box>
      </BackNavigationContent>
    </>
  )
}

export default AdditionalServicesInformation
